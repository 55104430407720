<script>
import def from "./default.vue";
export default {
  extends: def,
  data() {
    return {
      links: [
        { format: this.$t("format.title") },
        { styling: this.$t("design.title") },
        { time: this.$tc("dates.appointment", 2) },
        { settings: this.$t("settings.title") },
        { form: this.$t("form.title") },
        { survey: this.$t("survey.title") },
        { preview: this.$t("default.preview.title") }
      ]
    };
  },
  methods: {
    getData() {
      return this.$store.state.business.assistent;
    },
    checkStatus(index, data) {
      if (!data.type && index > 0) {
        return false;
      }

      if (!data.title && index > 1) {
        return false;
      }

      if (!data.form.length && index > 4) {
        return false;
      }

      if (!data.survey && index > 5) {
        return false;
      }

      if (data.type === "eventpicker") {
        return this.checkStatusEventpicker(index, data);
      }

      return true;
    },
    checkStatusEventpicker(index, data) {
      if (!data.events.length && index > 2) {
        return false;
      }
      return true;
    }
  }
};
</script>
